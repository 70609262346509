import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
/**
 * @file Manages requests to Firebase Authentication, including: sign-in, sign-up, sign-out, current user and passwort reset
 * @author Alexander Stöckl
 * @date 03.03.2022
 */
export class AuthService {

  public authState = this.auth.onAuthStateChanged;


  /**
   * Create instance of class and use AngularFireAuth Module and router
   * @constructor 
   */
  constructor(private auth: AngularFireAuth, private router: Router) {}

  /**
   * Tries to sign in a user with Firebase Auth and returns a promise.
   * @param {string} email 
   * @param {string} password 
   * @returns {Promise<string>}
   */
  public signIn(email: string, password: string): Promise<string> {
    return new Promise((res, rej) => {
      this.auth.signInWithEmailAndPassword(email, password)
        .then((data) => {
          if (data.user.emailVerified) {
            res(data.user.uid);
          } else {
            this.signOut();
            rej("verify email to sign in")
          }
        }).catch((err) => {
          rej(err.message);
        })
    })
  }

  /**
   * Tries to sign up a user with Firebase Auth and returns a promise.
   * @param {string} email 
   * @param {string} password 
   * @returns {Promise<string>}
   */
  public signUp(email: string, password: string): Promise<string> {
    return new Promise((res, rej) => {
      this.auth.createUserWithEmailAndPassword(email, password)
        .then((data) => {
          data.user.sendEmailVerification()
          .then(() => {
            res(data.user.uid);
          }).catch((err) => {
            rej(err.message);
          })
        }).catch((err) => {
          rej(err.message);
        })
    })
  }

  /**
   * Send Passwort Reset Mail to entered email and returns Promise
   * @param {string} email 
   * @returns {Promise<string>}
   */
  public resetPassword(email: string): Promise<string> {
    return new Promise((res, rej) => {
      this.auth.sendPasswordResetEmail(email)
        .then((data) => {
          res("Successfully sent Password Reset mail to: " + email);
        }).catch((err) => {
          rej(err.message);
        })
    })
  } 

  /**
   * Sign current user out and return promise
   * @returns {Promise<string>}
   */
  public signOut(): Promise<string> {
    return new Promise((res, rej) => {
      this.auth.signOut()
        .then(() => {
          res("Successfully signed out");
          this.router.navigate(["sign-in"]);
        }).catch(err => {
          rej(err.message);
        })
    })
  }

  /**
   * Verify the action code which was received via the url and check if it is still valid.
   * @param {string} code received code
   * @returns {Promise<string>}
   */
  public verifyPasswordResetCode(code: string): Promise<string> {
    return new Promise((res, rej) => {
      this.auth.verifyPasswordResetCode(code)
        .then((email) => {
          res(email)
        }).catch(err => {
          rej(err);
        })
    })
  }

  /**
   * Set new Password with the received action code and the new Password
   * @param {string} code received code
   * @param {string} newPassword new desired password
   * @returns {Promise<string>}
   */
  public confirmPasswordReset(code: string, newPassword: string): Promise<any> {
    return new Promise((res, rej) => {
      this.auth.confirmPasswordReset(code, newPassword)
        .then((resp) => {
          res(resp);
        }).catch(err => {
          rej(err);
        })
    })
  }

  /**
   * Verifies a Email Verify Code
   * @param {string} code received code
   * @returns 
   */
     public verifyEmailVerifyCode(code: string): Promise<any> {
      return new Promise((res, rej) => {
        this.auth.applyActionCode(code)
          .then((resp) => {
            res(resp);
          }).catch(err => {
            rej(err);
          })
      })
    }
}
