import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { User } from '../_classes/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
/**
 * @file Manages requests to list users on Firebase Realtime Database
 * @author Alexander Stöckl
 * @date 03.03.2022
 */
export class UserService {

  /**
   * Create instance of class and use AngularFireDatabase Module
   * @constructor 
   */
  constructor(private db: AngularFireDatabase) { }

  /**
   * Returns all user
   * @returns {Observable<User[]>}
   */
  public getAllUser() {
    return this.db.list<User>("/users/").snapshotChanges().pipe(
      map(users => 
        users.map(user => (
          {
            userID: user.payload.key,
            ...user.payload.val() 
          }
        ))
      )
    );
  }

  /**
   * Creates new user and returns Promise
   * @param {string} userID ID of the User
   * @param {User} user  User Object of User
   * @returns {Promise<string>}
   */
  public create(userID: string, user: User) {
    return new Promise((res, rej) => {
      this.db.list("/users/").set(userID, user).then(
        () => res("confirmation email sent to " + user.email + " - verify to sign in")
      ).catch((err) => {
          rej(err.message);
        })
    })
  }

  /**
   * Updates user and returns Promise
   * @param {User} user  User Object of User
   * @returns {Promise<string>}
   */
  public update(user: User) {
    return new Promise((res, rej) => {
      this.db.list("/users/").set(user.userID, user).then(
        () => res(user.email + " successfully updated")
      ).catch((err) => {
        rej(err);
      })
    })
  }

  /**
   * Returns user with the ID in the parameter
   * @param {string} userID User ID of User
   * @returns {Observable<User>}
   */
  public get(userID: string) {
    return this.db.object<User>("/users/" + userID).valueChanges();
  }
}
