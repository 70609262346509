import { Injectable, Injector } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as Sentry from "@sentry/browser";
//config sentry
Sentry.init({
  dsn: "https://090512f50d5844fabc6819a4edfc2827@o1161492.ingest.sentry.io/6247568"
});
@Injectable({
  providedIn: 'root'
})
/**
 * @file Curstom Error Handler
 * @author Alexander Stöckl
 * @date 03.03.2022
 */
export class ErrorHandlingService implements ErrorHandler {

  /**
   * Create instance of class and use ToastController to display messages to end user.
   * @constructor 
   */
  constructor(private toastController: ToastController) { }

  /**
   * Overwrites standard handleError and handles Error. Gets called when a Error is thrown.
   * @param {any} error The error to handle
   */
  handleError(error: any): void {
    //handle Errors from promises
    if (error.promise && error.rejection) {
      error = error.rejection;
    }

    //log exception in sentry
    Sentry.captureException(error);
    
    //console Error
    console.error(error);
    this.presentToast(error.message)
    

  }

  /**
   * Display a Toast Message to end user.
   * @async 
   * @param {string} text 
   */
  async presentToast(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 5000,
      color: "danger",
      position: 'bottom',
      cssClass: 'moveUp'
    });
    toast.present();
  }
  
}
