import { Component } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { UserService } from './_services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public loggedIn = false;
  public isBB = false;

  constructor(private authService: AuthService, private userService: UserService) {    
    this.authService.authState(user => {
      if (user) {
        this.loggedIn = true;
        this.userService.get(user.uid)
          .subscribe(u => {
            if (u) {
              if (u.role == "Berufsbildner") {
                this.isBB = true;
              }
            }
          })
      } else {
        this.loggedIn = false;
      }
    })
  }
}
